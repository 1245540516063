import React, { useEffect, useState, useContext } from 'react';

import axios from 'axios';
/* eslint-disable */
import SimpleLineChart from '../../../containers/Charts/Recharts/components/SimpleLineChart';
import {
  LineChart
} from 'recharts'; 
import { AppContext } from '../../../containers/App/App.jsx';

import Collapse from '@/shared/components/Collapse';
import data from './data';  

export default function Iotfirst() {
  const [iot, setIot] = useState([]);
  const {userData, setUserData} = useContext(AppContext);
  console.log("setUserData: "+JSON.stringify(setUserData)); 
  console.log("userData: "+JSON.stringify(userData));   
  //https://qq1h4pavsh.execute-api.eu-west-1.amazonaws.com/prod/sensortemp
  useEffect(() => {
    axios.get('http://protracs.io/api/devices-alldata')
      .then(({ data }) => {
        //alert(JSON.stringify(data));
        setIot(data.data);
        console.log('START'); 
        console.log('END');
      })
      .catch((error) => { 
        if (error.response) {
          // Запрос был сделан, и сервер ответил кодом состояния, который
          // выходит за пределы 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // Запрос был сделан, но ответ не получен
          // `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
          // http.ClientRequest в node.js
          console.log(error.request);
        } else {
          // Произошло что-то при настройке запроса, вызвавшее ошибку
          console.log('Error', error.message);
        }
        console.log(error.config);
        console.log('ERROR');
      });
  }, []);
  return (
    <>
      <div>IOT Data:</div>
      <table className="table mb-0 ">
        <thead className="bg-white text-uppercase">
          <tr className="ligth ligth-data">
            <th>Device EUI</th>
            <th>Status</th>
            <th>timestamp</th>
          </tr>
        </thead>
        <tbody className="ligth-body">
          {iot.map((i) =>
            <tr>
              <td>{i.name}</td>
              <td>up</td>
              <td>{i.created_at}</td>
            </tr>)}
        </tbody>
      </table>
     <SimpleLineChart />

     {data.map(({ id, title, text }) => (
            <Collapse key={id} title={title} className="with-shadow">
              <p>{text}</p>
              <p>{text}</p> 
            </Collapse>
          ))}

    </>
  );
}
