/* eslint-disable */
import React from 'react';
import { Col } from 'react-bootstrap';
import { Card } from '@/shared/components/Card';
import {
  PricingCard,
  PricingCardBody,
  PricingCardButton,
  PricingCardFeature,
  PricingCardImage,
  PricingCardPlan,
  PricingCardPrice,
} from './BasicElements';

import { mdiPoll } from '@mdi/js';
import ThermometerIcon  from 'mdi-react/ThermometerIcon';  
import Icon from '@mdi/react';   
import  {mdiHeatWave}  from '@mdi/js'; 
import { mdiThermometer } from '@mdi/js'; 
import { mdiHeatPumpOutline } from '@mdi/js'; 

const paperPlane = `${process.env.PUBLIC_URL}/img/pricing_cards/004-paper-plane.svg`;


const CardBlock = ({color, btitle, curvalue, valuetype, subtext, curvaluekwh, type1, heatmeter, accumulated}) => (
  <Col style={{ width: 195, height: 380}}>       
    <Card> 
      <PricingCard style={{boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px 0px'}}  color={color}>    
        <PricingCardBody>
        {btitle==='COP' && <Icon path={mdiPoll} size={4} color='#70bbfd' /> } 
        {btitle==='Heat Meter' && <Icon path={mdiHeatWave} size={4} color="#ff4861" />}  
        {btitle==='Temperature' && <Icon path={mdiThermometer} size={4} color="#4ce1b6" />}
        {btitle==='Heat Pump' && <Icon path={mdiHeatPumpOutline} size={4} color="#f6da6e" />}
          <PricingCardPlan>{btitle}
          
            </PricingCardPlan> 

            {(btitle==='Heat Meter') ?
            
            <PricingCardFeature> 
              <span class="cardcurvalue">
            {(btitle==='Heat Meter' && type1 == 'ALPHA') && <span>{(193.849 + accumulated).toFixed(3)}</span>}     
           {(btitle==='Heat Meter' && type1 == 'BETA') && (accumulated)}  
           </span>                     
           &nbsp;MWh   
           <br /> Accumulated     
         </PricingCardFeature> 

            :

            <PricingCardFeature  style={{ whiteSpace: 'nowrap'}}>
            {heatmeter && <><span class="cardcurvalue">{heatmeter}  </span>°C<br />System<br /></>}
              <span class="cardcurvalue">{(btitle==='Heat Pump' && type1 == 'ALPHA' && subtext == "Indoor") ? (parseFloat((parseFloat(curvalue) + 49169) / 1000)).toFixed(3) : curvalue}</span>&nbsp;
              {(btitle==='Heat Pump' && type1 == 'ALPHA' && (subtext == "Indoor" || subtext == "Outdoor")) ? <>MWh</> : valuetype }         
              
              {heatmeter && <><br />Outdoor</>} 
            {btitle==='Heat Meter1' && <><span class="cardcurvalue">{curvaluekwh}</span>&nbsp;KWh</>}  
  
            {subtext !=='' && <><br /><span class="clsubtext">{subtext}</span></> } 
            </PricingCardFeature>

          }

{/*
          {(btitle==='Heat Meter') &&
          <PricingCardFeature> 
             {(btitle==='Heat Meter' && type1 == 'ALPHA') && <span>{(193.849 + accumulated).toFixed(3)}</span>}     
            {(btitle==='Heat Meter' && type1 == 'BEATA') && '288.652'}                    
            &nbsp;MWh   
            <br /> Accumulated     
          </PricingCardFeature>     
          } 
*/} 
          {/* 
        {(btitle==='Heat Pump' && type1 == 'ALPHA' && subtext == "Indoor") &&
          <PricingCardFeature> 
            Accumulated<br />   
            {(btitle==='Heat Pump' && type1 == 'ALPHA') && <span>49.169</span>}                        
            &nbsp;MWh      
          </PricingCardFeature>      
          } 
          */}
          
        </PricingCardBody>
      </PricingCard>
    </Card>
  </Col>
);

export default CardBlock;
