/* eslint-disable */
/*
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { 
  persistStore,
  persistReducer, 
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
 
import auth from './slices/Auth.jsx'; 


 
const reducers = combineReducers({
    auth 
});  

const persistConfig = {
  key: 'root',
  storage,
}; 


const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }), 

});   

export const persister = persistStore(store);
export default store; 
*/


import { configureStore } from '@reduxjs/toolkit'
import auth from './slices/Auth.jsx';


export const store2 = configureStore({
    reducer: { 
        auth 
    },
}) 
