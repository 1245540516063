/* eslint-disable */
export const info = [
    {
      id: 1,
      name: "Abdul Fuller",
      phone: "1-280-847-1510",
      email: "ut@protonmail.com",
      country: "Costa Rica",
      alphanumeric: "BBX73IXM2UO",
    },
    {
      id: 2,
      name: "Fritz Hunter",
      phone: "(485) 251-8315",
      email: "malesuada@aol.org",
      country: "Australia",
      alphanumeric: "NXO38WBH6OY",
    },
    {
      id: 3,
      name: "Shelly Lucas",
      phone: "1-244-417-0372",
      email: "adipiscing.lobortis.risus@aol.ca",
      country: "Austria",
      alphanumeric: "MIX67LDV4VC",
    },
    {
      id: 4,
      name: "Austin Howell",
      phone: "1-568-430-2622",
      email: "in.consequat@yahoo.ca",
      country: "Colombia",
      alphanumeric: "WSH53KUD5DP",
    },
    {
      id: 5,
      name: "Harriet England",
      phone: "1-734-321-0237",
      email: "aliquet.proin.velit@google.ca",
      country: "Nigeria",
      alphanumeric: "TWS18YWE8TD",
    },
  ];