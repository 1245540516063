/* eslint-disable */
import {React, useState, useEffect, useContext} from 'react'; 
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card'; 
import axiosClient from "../../axiosClient.jsx";
import { useParams, useNavigate, Link } from "react-router-dom";   

export default function Companies (){
    console.log(1);

    //const userRole = "SuperAdmin"; 
    
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState([]);  

    useEffect(()=>{
        
        const getCompanies = async() => {   
                setIsLoading(1); 
                
                let apiStock = {}; 

                        apiStock = await axiosClient.get(`/v1/company`);
                        setCompanies(apiStock.data);   
 

                    //alert(JSON.stringify(apiStock.data))    

                                    
        }; 
        getCompanies(); 
    }, []);  

    return (
        <>
  <Col md={12}>
    <Card>
      <CardBody>
        <CardTitleWrap>
          <CardTitle>COMPANIES</CardTitle>

        </CardTitleWrap>  

        <>
                <div class="table-responsive rounded mb-3">
                <table class="table mb-0 "> 
                    <thead class="bg-white text-uppercase">
                        <tr class="ligth ligth-data">
                            <th>Name</th> 
                            <th>Status</th>
                            <th>Action</th> 
                        </tr>
                    </thead> 
                    <tbody class="ligth-body">
                    
                    {companies.map((company) => {
                    return (  
                        <tr>
                            <td>{company.name}</td>  
                            <td>{company.status == 1?'Active':'Inactive'}</td>
                            <td> 
                                <div class="d-flex align-items-center list-action">  
                                 

                                 <Link className="btn-add" to={'/editcompany/' + company.id}>&rarr;</Link>         

 
                                </div>  
                            </td> 
                        </tr>
                        );  
                    })} 
                  
                    </tbody>
                </table>
                </div>
    </>

      </CardBody>
    </Card>
  </Col>
  </>
    );
};