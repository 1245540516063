/* eslint-disable */
import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
 
const RADIAN = Math.PI / 180;
const data = [
  { name: 'A', value: 80, color: 'blue' }, 
];
const cx = 150;
const cy = 200;
const iR = 50;
const oR = 100;
      

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos; 
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
  ];
}; 

export default class PieChartWithNeedle extends PureComponent {
  render() { 
    var value = this.props.temperature  + (this.props.temperature); 
    if(this.props.campany_name == 'Enerpower_Enniscorty') 
      value = 21  + (21 * .99) + 0.3;  

    var max = "40";
    if(this.props.campany_name == 'Enerpower_Enniscorty') 
      var max = "100";
   
    //alert(JSON.stringify(this.props)); 
    return (
        <>
         
        <div class="relative">
            <div class="absolute mintotemp">
                0 
            </div> 
            <div class="absolute abstotemp">
                {this.props.temperature}&deg; 
            </div>
            <div class="absolute maxtotemp">
                {max} 
            </div>   
        </div>
       
      <PieChart width={400} height={230}>
        <Pie
          dataKey="value"
          startAngle={180} 
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(value, data, cx, cy, iR, oR, 'black')}
      </PieChart>  
      </>
    );
  }
}
