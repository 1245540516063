/* eslint-disable */
import React from "react";
import { ExpendableButton } from "./ExpendableButton";
import { TableRow } from "./TableRow";
import useOpenController from "../Hooks/useOpenController";
import { Link } from "react-router-dom"; 
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button'; 

export const TableSection = ({ personDetails, company, index }) => {
  const { isOpen, toggle } = useOpenController(false);
  return (
    <tbody>
      <tr class="main-companies"> 
        <td className="button-td">
          <ExpendableButton isOpen={isOpen} toggle={toggle} />
        </td>
        <td> 
          <b>{index + 1}</b> 
        </td>
        <td>{company.name}</td>
        <td>{company.main_contact}</td> 
        <td>{company.username}</td> 
        <td>{company.total_users}</td>
        <td>{company.created_at}</td> 
        <td>0</td> 
        <td><span class="badge badge-success ng-star-inserted">Active</span></td>
        <td>
        <Link   to={'/editcompany/' + company.id}> 
                                    <ButtonToolbar>  
                                     <ButtonGroup icons dir="ltr">
                                    <Button variant="outline-secondary"><span className="lnr lnr-pencil" /></Button>
                                     </ButtonGroup>
                                    </ButtonToolbar>
        </Link>      
        </td>
        <td>{company.url && <a href={company.url}>&#x2192;</a>}</td>   
      </tr>
      {isOpen && <TableRow users={company.users} personDetails={personDetails} />}
    </tbody> 
  );
};