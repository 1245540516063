/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import CardBlock from './components/CardBlock';
import CardBasic from './components/CardBasic';
import CardSpecial from './components/CardSpecial';
import CardPro from './components/CardPro';
import CardPremium from './components/CardPremium';

const PricingCards = ({cop, heatmeter, temperature, temperaturekwh, heatpump, heatpumpout, type1, accumulated}) => (
  <>
  <Container>
    <Row style={{ width: 1130}} dir="ltr" >  
      <CardBlock curvalue={heatpumpout} valuetype="MWh" bicon='' btitle='Heat&nbsp;Pump' color="warning" subtext="Outdoor" type1={type1} /> 
      <CardBlock curvalue={heatpump} valuetype="MWh" bicon='' btitle='Heat&nbsp;Pump' color="warning" subtext="Indoor" type1={type1} />      
      <CardBlock curvalue={heatmeter} curvaluekwh={temperaturekwh} valuetype="&deg;C" bicon='' btitle='Heat&nbsp;Meter' color='danger' type1={type1} accumulated={accumulated}  /> 
      <CardBlock curvalue={temperature} valuetype="&deg;C" bicon='' btitle='Temperature' type1={type1} heatmeter={heatmeter} />
      <CardBlock curvalue={cop} valuetype="Q/W"  bicon='' btitle='COP' color='primary' type1={type1} />     
      </Row>   
  </Container>   
    </>
);

export default PricingCards;
