/* eslint-disable */
import {Link} from "react-router-dom";
import axiosClient from "../../axiosClient.jsx"; 
import {createRef} from "react";
import { useState } from "react";

import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar, 
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';

import { Col } from 'react-bootstrap';  
 
import validate from './validateResetPassword'; 
 
export default function ForgotPassword() {      

  const params = new URLSearchParams(location.search);   
  const emailRef = createRef()
  const [message, setMessage] = useState(null)
  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(null)

  const onSubmit = async (values) => { 

    const payload = {
      email: values.email,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
      token: params.get("token"), 
    } 
    axiosClient.post('/reset-password', payload)
      .then(({data}) => {   

        setSuccess(data);
                     
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          //setErrors(response.data.errors)
          setErrors(response.data.message) 
        } 
      })
  }

    return (
      <>

                <Col xs={12} md={12} lg={12} xl={6}>
                <Card>
                <CardBody>

                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Set new Password  
          </h2> 
          {errors &&
            <div className="sm:mx-auto sm:w-full sm:max-w-sm text-red-900">
              <p>{errors}</p> 
            </div>
          }
          <br/>
        </div> 
        {success ? 
            <div className="sm:mx-auto sm:w-full sm:max-w-sm text-red-900">
              <p>{success}</p>
            </div>
            : 

                <Form onSubmit={onSubmit} validate={validate}> 
            {({ handleSubmit, form, values }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>

                    <FormGroup>
                        <FormGroupLabel>Email</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="email"
                            component={FormField}
                            type="text"
                            placeholder=""
                            isAboveError
                          />
                        </FormGroupField>
                      </FormGroup>

                      
                      <FormGroup>
                        <FormGroupLabel>Password</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="password"
                            component={FormField}
                            type="password"
                            placeholder=""
                            isAboveError
                          />
                        </FormGroupField>
                      </FormGroup> 

                      <FormGroup>
                        <FormGroupLabel>Repeat Password</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="passwordConfirmation"
                            component={FormField}
                            type="password"
                            placeholder=""
                            isAboveError
                          />
                        </FormGroupField>
                      </FormGroup> 

                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Submit</Button>
                  <Button variant="secondary" type="button" onClick={form.reset}>
                    Cancel
                  </Button>
                </FormButtonToolbar>
              </FormContainer>
            )} 
          </Form>
          }

          </CardBody>
          </Card>
          </Col>

    </>
    )
}