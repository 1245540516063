/* eslint-disable */
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types'; 
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import axios from 'axios';
import axiosClient from "../../../../axiosClient.jsx";



const Data1LineChart = ({devices, dir, id, field, title, key }) => {

  const [data, setData] = useState( [
    {
      name: 'Page A', uv: 4000,
    },
    {
      name: 'Page B', uv: 3000,
    },
    {
      name: 'Page C', uv: 2000,
    },
    {
      name: 'Page D', uv: 2780,
    },
    {
      name: 'Page E', uv: 1890,
    },
    {
      name: 'Page F', uv: 2390,
    },
    {
      name: 'Page G', uv: 3490, 
    },
  ]);

  useEffect(()=>{
    //alert("9999999: "+JSON.stringify(devices)); 
      let i = 0; 
      const array = [];
      devices.map((d) => { 
        console.log('map'); 
        i++;
        //alert(d.temperature);
        var gData = "";
        if(field == 'data1') 
          gData = d.data1;
        if(field == 'data2') 
          gData = d.data2;

        let set = {name: d.created_at, value: gData};     
        array.push(set);
        //console.log('map end');  
      })
      console.log("IIIIIIIIIIII:"+JSON.stringify(array));
      setData(array); 
  }, [devices]);

  const { t } = useTranslation('common');

  return (
    <Col xs={12} md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{title}</CardTitle>
          </CardTitleWrap>
          <div dir="ltr">
            <ResponsiveContainer width={500} height={300}>
              <LineChart 
                data={data}
                margin={{
                  top: 0, right: 0, left: -15, bottom: 0,
                }}
              >
                <XAxis dataKey="name" reversed={dir === 'rtl'} />
                <YAxis orientation={dir === 'rtl' ? 'right' : 'left'} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#70bbfd" />
                {/*<Line type="monotone" dataKey="value" stroke="#4ce1b6" activeDot={{ r: 8 }} />*/}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

Data1LineChart.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default Data1LineChart;
