/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const userData = (typeof localStorage.getItem('userData') === "undefined" || localStorage.getItem('userData') === null) ? {} : JSON.parse(localStorage.getItem('userData'));
console.log("AUTH!!!!!!!! userData!!!!!!!!!!!!!---------->"+JSON.stringify(userData)); 
const currentBar = (typeof localStorage.getItem('currentBar') === "undefined" || localStorage.getItem('currentBar') === null) ? {} : JSON.parse(localStorage.getItem('currentBar'));
const roles = (typeof localStorage.getItem('roles') === "undefined" || localStorage.getItem('roles') === null) ? {} : JSON.parse(localStorage.getItem('roles'));    

const initialState = {      
    email: '11111',
    userToken: '',
    userData: userData,
    roles: roles,
    currentBar: currentBar   
}       

const authSlice = createSlice({
    name: 'auth', 
    initialState,
    reducers: {
        setuseremail: (state, action) => {
            state.email = action.payload;
            console.log("REDUX Token !!! - "+action.payload);
        },
        setusertoken: (state, action) => {
            state.userToken = action.payload;
            console.log("REDUX Token !!! - "+action.payload);
        },
        setuserroles: (state, action) => { 
            state.roles = action.payload;
            console.log("REDUX roles state.roles !!! - "+state.roles);
            localStorage.setItem("roles", JSON.stringify(action.payload));             
        }, 
        setUserData: (state, action) => { 
            alert(1); 
            state.userData = action.payload;
            console.log("setUserData !!!!!!!!!!!!!! - "+JSON.stringify(action.payload));  
            localStorage.setItem("userData", JSON.stringify(action.payload));  
        },
        setCurrentBar: (state, action) => { 
            state.currentBar = action.payload;
            console.log("setCurrBar !!! - "+state.roles);
            localStorage.setItem("currentBar", JSON.stringify(action.payload));            
        },     
    },
})

export const { setuseremail, setusertoken, setuserroles, setUserData, setCurrentBar } = authSlice.actions;
export default authSlice.reducer;    