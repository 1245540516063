/* eslint-disable */
import {React, useState, useEffect, useContext} from 'react'; 

export const Paginator = ({totalPage, currentPage, outputPaginPages, ismounted, handleClickedPage}) => {

 

 
  return (
    <> 
    {totalPage > 1 &&  
    
    <div class="sc-jgaZKu jWDfwP"> 

      
    <ul class="pagination"> 
    {currentPage > 1 ?
      <li onClick={ () => { handleClickedPage(1)}} class="pagination__item--arrow page-item"><span class="page-link"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z"></path></svg></span><span class="visually-hidden">First</span></span></li>
      :
      <li class="pagination__item--arrow page-item disabled"><span class="page-link"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z"></path></svg></span><span class="visually-hidden">First</span></span></li>      
    } 

        {(currentPage - 1) > 0 ? 
                    <li onClick={ () => {handleClickedPage(currentPage - 1)}} class="pagination__item--arrow page-item"><span class="page-link"><span aria-hidden="true"> <svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg>
                                </span><span class="visually-hidden">Previous</span></span></li> 
        :
        <li  class="pagination__item--arrow page-item disabled"><span class="page-link"><span aria-hidden="true"> <svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg>
        </span><span class="visually-hidden">Previous</span></span></li>
      }
                                {(ismounted === 1 && outputPaginPages[0]) && 
                                <>                        
                            {outputPaginPages.map(npage => {
                              
                              return ( 
                                <>
                                {currentPage == npage ?
                                <li  onClick={ () => {
                                  handleClickedPage(npage)}} class="page-item active"><span class="page-link">{npage}<span class="visually-hidden">(current)</span></span></li>
                                  :
                                  <li  onClick={ () => {
                                    handleClickedPage(npage)}} class="page-item"><a class="page-link" role="button" tabindex="0" href="#">{npage}</a></li>
                                }
                                </>
                              );  
                            })}
                          </> 
                          } 

                              {((currentPage + 1) <= totalPage) ? 
                                <li onClick={ () => {handleClickedPage(currentPage + 1)}} class="pagination__item--arrow page-item"><a class="page-link" role="button" tabindex="0" href="#"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path></svg></span><span class="visually-hidden">Next</span></a></li>
                                    :
                                    <li class="pagination__item--arrow page-item disabled"><a class="page-link" role="button" tabindex="0" href="#"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path></svg></span><span class="visually-hidden">Next</span></a></li>
                              }
                              {(currentPage != totalPage) ? 
                                <li onClick={ () => {handleClickedPage(totalPage)}} class="pagination__item--arrow page-item"><a class="page-link" role="button" tabindex="0" href="#"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z"></path></svg></span><span class="visually-hidden">Last</span></a></li>
                                :
                                <li  class="pagination__item--arrow page-item disabled"><a class="page-link" role="button" tabindex="0" href="#"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z"></path></svg></span><span class="visually-hidden">Last</span></a></li>
                              } 
    </ul>{/*<div class="sc-chKlql hMGnbD">Showing 1 to 10 of 35</div>*/}<div class="sc-chKlql sc-jEItEs hMGnbD fBkzoO">
      </div></div>
    }
    </>
  );
};