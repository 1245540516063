/* eslint-disable */
import {createRef, useState, useEffect} from "react";
import axiosClient from "../../axiosClient.jsx";
import { useParams, Link } from "react-router-dom";  

 

export default function SetUserCompany() {   

    const userRole = "SuperAdmin"; 

    const {id} = useParams();

    const [companies, setCompanies] = useState()
    const [company, setCompany] = useState()
 
    const [user, setUser] = useState({
        id: null,
        name: '', 
        email: ''
    })

    const nameRef = createRef() 
    const emailRef = createRef() 
    const passwordRef = createRef()
    const passwordConfirmationRef = createRef() 
    const roleRef = createRef() 

    const [errors, setErrors] = useState(null)
  
    const onSubmit = ev => {
      ev.preventDefault()

      const payload = {
        user_id: id,
        company_id: company,
      }    

      console.log("payload!!!: "+JSON.stringify(payload)); 

      let url = ''; 

        url = '/setcompany';


      axiosClient.post(url, payload) 
        .then(({data}) => {
            //navigate('/users');
            alert("company added to user!");
        })
        .catch(err => { 
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors)
          }
        }) 
    }

    if(id) { 
        useEffect(()=>{
            const getStocks = async() => {
               

                const apiStock = await axiosClient.get(`/v1/company`); 
alert(apiStock.data);  
                setCompanies(apiStock.data);   

            };
            getStocks();
        }, []);
    }

    return (
        <>

                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <div class="header-title"> 
                            <h4 class="card-title">Set company</h4>
                        </div>
                    </div>
                    <div class="card-body">
                    {errors &&
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm text-red-900">
                        {Object.keys(errors).map(key => (
                            <p key={key}>{errors[key][0]}</p>
                        ))}
                        </div>
                    } 
                        <form onSubmit={onSubmit} data-toggle="validator">
                            <div class="row">                                
                                <div class="col-md-12"> 
                                    <div class="form-group">
                                    {companies &&
                                        <select 
                                            ref={roleRef}   
                                            id="role" 
                                            name="role" class="form-control" data-style="py-0"
                                            onChange={(ev) =>setCompany(ev.target.value)} 
                                            >

                                            {Object.keys(companies).map(key => (  
                                                <option value={companies[key].id}>{companies[key].name}</option> 
                                            ))}   
                                            
                                        </select>
                                        }
                                    </div>   
                                </div>
                                                                  
                            </div>   
                            
                            <button type="submit" class="btn sidebar-bottom-btn">Submit</button>

                        </form> 
                    </div>
                </div>
      </>
    )
}