/* eslint-disable */
const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Name field shouldn’t be empty';
    }
  
    return errors;
  };
  
  export default validate;
  