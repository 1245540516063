/* eslint-disable */
import {React, useState, useEffect, useContext} from 'react'; 
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card'; 
import axiosClient from "../../axiosClient.jsx";
import { useParams, useNavigate, Link } from "react-router-dom";  
import Creatable from 'react-select/creatable';  


import PieChartWithNeedle from '../../containers/Charts/Recharts/components/PieChartWithNeedle';
import PieChartWithNeedle2 from '../../containers/Charts/Recharts/components/PieChartWithNeedle2';
import SimpleAreaChart from '../../containers/Charts/Recharts/components/SimpleAreaChart';
import HumidityAreaChart from '../../containers/Charts/Recharts/components/HumidityAreaChart';
import Data1Chart from '../../containers/Charts/Recharts/components/Data1Chart';
import Data1LineChart  from '../../containers/Charts/Recharts/components/Data1LineChart';




import { NavLink } from 'react-router-dom'; 

export default function DeviceData (){
    console.log(1); 
    const {id} = useParams(); 

    const userRole = "SuperAdmin";

    const [pieData, setPieData] = useState({});
    const [deviceDataLast, setDeviceDataLast] = useState([]);
    const [device, setDevice] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const [chart1Field, setchart1Field] = useState();
    const [chart2Field, setchart2Field] = useState();

    const [pieData1, setpieData1] = useState({titleD: '', max: 100, value: 0, outputvalue: '', link : ''}); 
    const [pieData2, setpieData2] = useState({titleD: '', max: 100, value: 0, outputvalue: '', link : ''}); 
    const [pieData3, setpieData3] = useState({titleD: '', max: 100, value: 0, outputvalue: '', link : ''});  

    useEffect(()=>{

    const deviceMainData = async() => {     
            let device = {};
            if(userRole === "SuperAdmin") { 

                device = await axiosClient.get(`/devicebyid/`+id);  
                    //alert(JSON.stringify(device.data));
                    setDevice(device.data);   
            }                     
    };  
    deviceMainData();
        
      const getUsers = async() => {     
              setIsLoading(1);
              
              let apiStock = {};
              if(userRole === "SuperAdmin") { 
 
              } 

              setIsLoading(0);                      
      };  
      getUsers();   
  }, []);  

    
    const [devices, setDevices] = useState([]);  

    useEffect(()=>{
        
        const getDevices = async() => {   
                setIsLoading(1); 
                
                let apiStock = {}; 
 
                        apiStock = await axiosClient.get(`/devicedatabydeviceid/${id}`);
                        if(device.campany_name == 'Enerpower_Enniscorty' && (id == 26 || id == 25)) {
                            setchart1Field('data1');
                            setchart2Field('data2');
                        }
                        if(id == 23) { 
                            let proc = (apiStock.data.data[0].data2 / 100000) * 100;
                            setPieData({max: '100,000', value: proc, outputvalue: apiStock.data.data[0].data2 });     
                        }
                        //alert(JSON.stringify(apiStock.data.data[0]))    
                      
                        setDeviceDataLast(apiStock.data.data[0]);   
                        setDevices(apiStock.data.data);  
                        
                       let pieData = {titleD: apiStock.data.data[0].name, max: 40, value: apiStock.data.data[0].temperature, outputvalue: apiStock.data.data[0].temperature, link : '/devicedata/' + apiStock.data.data[0].id}; 
                       setpieData1(pieData); 

                       let pieData2 = {titleD: apiStock.data.data[0].name, max: 40, value: apiStock.data.data[0].humidity, outputvalue: apiStock.data.data[0].humidity, link : '/devicedata/' + apiStock.data.data[0].id}; 
                       setpieData2(pieData2);  

                        //alert("88888888888: "+JSON.stringify(apiStock.data.data));            
        }; 
        getDevices(); 
    }, []);  

    return (
      <>
          {isLoading?     <div id="loading">   
          <div id="loading-center"> 
          </div> 
        </div>: ''}   
                <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                <div>   
                <NavLink to="/devices">Devices list</NavLink> &rarr; {device.name} 
                </div> 
                </div>  

 

                {device.campany_name == 'Enerpower_Enniscorty' && (id == 26 || id == 25) ?
                <>
                <div><b>Modbus 1 last value: </b>{deviceDataLast.data1}</div>  
                <div><b>Modbus 2 last value: </b>{deviceDataLast.data2}</div>  
                </>
                :  
                <>
                {pieData.value ?
                <>
                <PieChartWithNeedle2 data={pieData}/>- 
                </>
                :  
                <div class="row">

                <div class="col-xl-4 col-lg-6 col-md-12 col-12 pieh">
                {pieData1.value > 0 && <PieChartWithNeedle2 data={pieData1} title="Temperature" />}
                    </div>  
                
                <div class="col-xl-4 col-lg-6 col-md-12 col-12 pieh"> 
                    {pieData1.value > 0 && <PieChartWithNeedle2 data={pieData2} title="Humidity" />} 
                </div>
                
                <div class="col-xl-4 col-lg-12 col-md-12 pieh"><PieChartWithNeedle temperature={deviceDataLast.temperature} campany_name={device.campany_name} /></div> 
                
                </div>   
                
                }
                </>
                }

                

                {device.campany_name == 'Enerpower_Enniscorty' && (id == 26 || id == 25 || id == 23) ? 
                <table>
                <tr>
                    <td>
                       {/* {Object.keys(devices).length > 0 && <Data1Chart devices={devices} id={id} field='data1'  />} */}
                        {(id != 23) &&<Data1LineChart devices={devices} id={id} field='data1' title="Modbus 1" key="Modbus_1"  />}
                    </td>
                    <td>
                        {Object.keys(devices).length > 0 && <Data1Chart id={id} field='data2' title="Historic Energy Production" devices={devices} />} 
                    </td>  
                </tr>
                </table>
                :
                <table>
                    <tr>
                        <td>
                         {/*   <SimpleAreaChart id={id} /> */}
                        </td>  
                        <td>
                         {/*   <HumidityAreaChart  id={id} campany_name={device.campany_name} /> */}
                        </td>
                    </tr>
                </table>
                }

                 

                <div class="table-responsive rounded mb-3">
                <table class="table mb-0 "> 
                    <thead class="bg-white text-uppercase">
                        <tr class="ligth ligth-data">
                            <th>Name</th>
                            <th>Status</th>  
                            <th>Date</th>
                        </tr> 
                    </thead> 
                    <tbody class="ligth-body">
                    {devices.map((device) => {
                    return (   
                        <tr>
                            <td>{device.name}</td>   
                            <td>{device.title}</td>   
                            <td>{device.created_at}</td>   
                        </tr>
                        );  
                    })}
                    </tbody>
                </table>
                </div>

                

    </>
    );
};