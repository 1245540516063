/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { colorBackgroundBody } from '@/utils/palette';
import { paddingLeft } from '@/utils/directions';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import Register from '../UserControl/Register';
import ExamplePageOne from '../Example/index';
import ExamplePageTwo from '../ExampleTwo/index';

import CompanyManage from '../Company/CompanyManage';
import Companies from '../Company/Companies';
import Devices from '../Device/Devices';
import DeviceData from '../Device/DeviceData'; 


import Ulist from '../People/Ulist';

import UserManage from '../People/UserManage';
import SetUserCompany from '../People/SetUserCompany';
import PersonalInformation from '../People/PersonalInformation';
import ChangePassword from '../People/ChangePassword';
import ForgotPassword from '../People/ForgotPassword';
import ResetPassword from '../People/ResetPassword'; 

import Main from '../MainPage/Main'; 
 
 



const Pages = () => ( 
  <Switch>
    <Route path="/pages/one" component={ExamplePageOne} />
    <Route path="/pages/two" component={ExamplePageTwo} />
    
  </Switch>
);

const wrappedRoutes = () => (
  <div class="main_content">
    <Layout />
    <ContainerWrap> 
    
      <Route path="/" component={Main} />  

      <Route path="/devices" component={Devices} />
      <Route path="/devicedata/:id" component={DeviceData} /> 

      <Route path="/pages" component={Pages} />

      <Route path="/addcompany" component={CompanyManage} />
      <Route path="/editcompany/:id" component={CompanyManage} />     
      <Route path="/companies" component={Companies} /> 

      

      <Route path="/users" component={Ulist} />

      <Route path="/user/:id" component={UserManage} />


      <Route path="/setusercompany/:id" component={SetUserCompany} />

            
      <Route path="/usercreate" component={UserManage} /> 
      <Route path="/personal-information" component={PersonalInformation} />
      <Route path="/change-password" component={ChangePassword} />  
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} /> 

       
    </ContainerWrap>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch> 
        
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/register" component={Register} />
        <Route path="/" component={wrappedRoutes} />

 
        
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;

// region STYLES

const ContainerWrap = styled.div`
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  ${paddingLeft}: 0;

  background: ${colorBackgroundBody};

  @media screen and (min-width: 576px) {
    ${paddingLeft}: 30px;
    padding-right: 30px;   
  }

  @media screen and (max-width: 576px) {
    padding-top: 150px;
  }
`;

// endregion
