/* eslint-disable */
import {React, createRef, useState, useContext} from 'react';
import {NavLink, Link, useParams, useHistory} from "react-router-dom";
import axiosClient from "../../axiosClient.jsx"; 
//import {useStateContext} from "../context/ContextProvider.jsx";
//import { useParams, useNavigate } from "react-router-dom";  

//import { useSelector, useDispatch } from 'react-redux'
//import { setuseremail, setusertoken,  setuserroles, setUserData, setCurrentBar} from '../Redux/slices/Auth.jsx';

import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon'; 
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import {
  FormGroup,
  FormGroupField, 
  FormGroupIcon,
  FormGroupLabel, 
} from '@/shared/components/form/FormElements';
import { AccountButton, AccountForgotPassword, LoginForm,  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountOr,
  AccountSocial,
  AccountSocialButtonFacebook,
  AccountSocialButtonGoogle,
  AccountTitle,
  AccountWrap, } from '@/shared/components/account/AccountElements';

  import { AppContext } from '../App/App.jsx'; 
 
export default function Register() {
  const {userData, setUserData} = useContext(AppContext); 
  const history = useHistory();      
  //const navigate = useNavigate(); 
  //const dispatch = useDispatch();  

  const nameRef = createRef()
  const emailRef = createRef()
  const passwordRef = createRef()
  const passwordConfirmationRef = createRef() 

  //const { setUser, setToken } = useStateContext()
  const [message, setMessage] = useState(null)
  const [errors, setErrors] = useState(null)   

  const onSubmit = ev => { 
    ev.preventDefault()
    const payload = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      password: passwordRef.current.value,
      password_confirmation: passwordConfirmationRef.current.value,
    }
    axiosClient.post('/signup', payload) 
      .then(({data}) => {
        console.log("yes!!!! registered: "+data.user); 
        console.log("yes!!!! registered: "+data.token); 
        setUserData(data)
        //alert(data.user.id) 
        history.push('/pages/one');   
        //setUser(data.user)
        //setToken(data.token);
        //history.push('/pages/one');  
      })
      .catch(err => {
        const response = err.response;
        if (response && response.status === 422) {
          setErrors(response.data.errors)
        }
      })
  }

    return (
      <>
        <AccountWrap>
        <AccountContent>
        <AccountCard>

        <AccountHead>
          <AccountTitle>
          Sign up to your account
          </AccountTitle>

        </AccountHead>
        {errors &&
            <div className="sm:mx-auto sm:w-full sm:max-w-sm text-red-900">
              {Object.keys(errors).map(key => (
                <p key={key}>{errors[key][0]}</p>
              ))}
            </div>
          } 
<Form onSubmit={onSubmit}>
    {({ handleSubmit }) => (
      <LoginForm onSubmit={onSubmit}>

        <FormGroup> 
          <FormGroupLabel>Name</FormGroupLabel>
          <FormGroupField>
            <FormGroupIcon>
              <AccountOutlineIcon />
            </FormGroupIcon>

            <Field
              ref={nameRef}
              id="name"
              name="name"
              component="input"
              type="text"
              placeholder="Name"
              required
            />
          </FormGroupField>
        </FormGroup>

        <FormGroup> 
          <FormGroupLabel>Email</FormGroupLabel>
          <FormGroupField>
            <FormGroupIcon>
              <AccountOutlineIcon />
            </FormGroupIcon>

            <Field
              ref={emailRef}
              id="email"
              name="email"
              component="input"
              type="text"
              placeholder="Email"
              required
            />
          </FormGroupField>
        </FormGroup>
        <FormGroup>
          <FormGroupLabel>Password</FormGroupLabel>
          <FormGroupField> 
            <Field
              ref={passwordRef}
              id="password"
              name="password"
              component="input"
              type="password" 
              placeholder="Password"
              required
              keyIcon
            /> 
            <AccountForgotPassword>
              <NavLink to="/">Forgot a password?</NavLink>
            </AccountForgotPassword>
          </FormGroupField>
        </FormGroup> 

        <FormGroup>
          <FormGroupLabel>Repeat Password</FormGroupLabel>
          <FormGroupField> 
            <Field
              ref={passwordConfirmationRef}
              id="passwordConfirmation"
              name="passwordConfirmation"
              component="input"
              type="password" 
              placeholder="Password"
              required
              keyIcon
            /> 
            <AccountForgotPassword>
              <NavLink to="/">Forgot a password?</NavLink>
            </AccountForgotPassword>
          </FormGroupField>
        </FormGroup> 

        <AccountButton
          type="submit"
          variant="outline-primary"
        >
          Register
        </AccountButton> 
        <AccountButton
          as={NavLink}
          variant="outline-primary"
          to="/login"
        >
          Login
        </AccountButton>
      </LoginForm>
    )}
  </Form> 

  </AccountCard>
  </AccountContent>
  </AccountWrap>
    </>
    )
}