/* eslint-disable */
const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
        errors.firstname = 'First Name field shouldn’t be empty';
    }
    if (!values.lastname) {
        errors.lastname = 'Last Name field shouldn’t be empty';
    }
    if (!values.email) {
        errors.email = 'Last Name field shouldn’t be empty';
    }
    if (!values.password) {
        errors.password = 'Password field shouldn’t be empty';
    }
    if (!values.role) {
        errors.role = 'Role field shouldn’t be empty';
    }
    
  
    return errors;
  };
  
  export default validate;
  