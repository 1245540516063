/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";  
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';

export const TableRow = ({ users, personDetails }) => {
  return (
<>
    {users.map((user) => {  

      return (
    <tr>
      <td></td>
      <td class="user-row">{user.id}</td>
      <td class="user-row">{user.firstname} {user.lastname}</td>
      <td class="user-row">{user.email}</td> 
      <td class="user-row"></td>
      <td class="user-row"></td>
      <td class="user-row"></td>
      <td class="user-row"></td>
      <td class="user-row"><span class="badge badge-success ng-star-inserted">Active</span></td>
      <td class="user-row">                              
                                <Link   to={'/user/' + user.id}>    
                                    <ButtonToolbar>  
                                     <ButtonGroup icons dir="ltr">
                                    <Button variant="outline-secondary"><span className="lnr lnr-pencil" /></Button>
                                     </ButtonGroup>
                                    </ButtonToolbar>
                                </Link> 
      </td>
      <td class="user-row"></td>
    </tr> 
    );  
  })} 
   </>  
  );

};