/* eslint-disable */
import React from 'react';
import {useState, useEffect, useContext} from "react"; 
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
} from './BasicTopbarComponents';

import logoD from '../../../img/ProTracs-Logo-051023.png';
import axiosClient from "../../../axiosClient.jsx"; 
import { AppContext } from '../../../containers/App/App.jsx'; 

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  const [logo, setLogo] = useState(logoD);
  const {company, setCompany} = useContext(AppContext);  

  useEffect(()=>{
    if(window.location.host !== 'localhost:3000' && window.location.host !== 'dashboard.protracs.io') {
      console.log("window.location.host: "+window.location.host); 
      const getCompany = async() => {

        const apiStock = await axiosClient.get(`/companybyurl/${window.location.host}`);  
        //alert(JSON.stringify(apiStock.data));   
        setCompany(apiStock.data[0]); 
        //setLogo("http://backendiot2.loc/storage/uploads/"+apiStock.data[0].logo);
        setLogo("http://protracs.io/storage/uploads/"+apiStock.data[0].logo);    
 
    };
    getCompany();
    }
}, []);

return(
  <TopbarContainer>
    <TopbarLeft>
      {/* 
      <TopbarSidebarButton
        onClickMobile={changeMobileSidebarVisibility}
        onClickDesktop={changeSidebarVisibility}
      />
      */}
      <div class="block-logo">
      <img class="logo"  src={logo} alt="logo" /> 
      </div>
      <TopbarLogo to="/" />   
    </TopbarLeft> 
    <TopbarRight>
      <TopbarProfile />
    </TopbarRight>
  </TopbarContainer>
)};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
