/* eslint-disable */
import {React, useState, useEffect, useContext} from 'react'; 
import { info } from "../data/info.js";
import { TableSection } from "./TableSection.jsx";
import axiosClient from "../../../axiosClient.jsx";

import { Paginator } from '../../GeneralComponents/Paginator.jsx';  

export const Table = ({companiesSearch}) => {

    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState([]); 

    const [outputp, setOutputp] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [postsPerPage] = useState(3);
    const [totalPage, setTotalPage] = useState(1);
    const [ismounted, setIsmounted] = useState(0);
  
    const handleClickedPage = (setPage) => {
      setCurrentPage(setPage);
      alert("!setPage: "+setPage);   
   };

    
    useEffect(()=>{ 
      setCompanies(companiesSearch);
    }, [companiesSearch]);  

    useEffect(()=>{
        
        const getCompanies = async() => {   
                setIsLoading(1); 
                
                let apiStock = {};  

                        apiStock = await axiosClient.get(`/v1/companieswithusers?page=${currentPage}`); 
                        setCompanies(apiStock.data);
                        //console.log("PPPPPPPPPPPP: "+apiStock.data.meta.last_page);  
                        setTotalPage(apiStock.data.meta.last_page);    
                        let data = [];
                        if(apiStock.data.meta.last_page > 0) {  
                            for(let i = 0; i < apiStock.data.meta.last_page; i++) {
                                data.push(i+1);  
                            }   
                             
                            setOutputp(data); 
                            setIsmounted(1);
                            //alert("----------||||||"+JSON.stringify(outputp))   
                        } 
 
                    //alert(JSON.stringify(apiStock.data))    

                                    
        }; 
        getCompanies(); 
    }, []); 

  return (
    <span class="main-layout">
    <table class="mainPageCompanies">
      <thead>
        <td></td>
        <th>#</th>
        <th>Company</th> 
        <th>Main Contact</th>
        <th>Username</th>
        <th>No of Users</th>
        <th>Active Date</th>
        <th>No of Locations</th>
        <th>Status</th> 
        <th></th>
        <th></th> 
      </thead>
      {companies.map((company, index) => (
        <TableSection company={company} index={index} />
      ))} 
    </table>  

    <Paginator  totalPage={totalPage} currentPage={currentPage} outputPaginPages={outputp} ismounted={ismounted} handleClickedPage={handleClickedPage}  /> 

    </span>
  );
};