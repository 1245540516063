/* eslint-disable */
const validate = (values) => {
    const errors = {}; 
    if (!values.email) {
        errors.email = 'Email field shouldn’t be empty';
    }  
    if (!values.password) {
        errors.password = 'Password field shouldn’t be empty';
    }  
    return errors;
  }; 
  
  export default validate; 