/* eslint-disable */
import {React, useState, useEffect, useContext} from 'react'; 
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card'; 
import axiosClient from "../../axiosClient.jsx";
import { useParams, useNavigate, Link } from "react-router-dom";  
import Creatable from 'react-select/creatable'; 

import { Table } from '@/shared/components/TableElements'; 
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import { AppContext } from '../../containers/App/App.jsx'; 

import { Paginator } from '../GeneralComponents/Paginator.jsx'; 

export default function Ulist (){
    const {company, setCompany} = useContext(AppContext); 
    console.log(1);

    const [outputp, setOutputp] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1); 
    const [postsPerPage] = useState(3);
    const [totalPage, setTotalPage] = useState(1);
    const [ismounted, setIsmounted] = useState(0);
    

    const userRole = "SuperAdmin";
    
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState([]);

    const handleClickedPage = (setPage) => {
        setCurrentPage(setPage);
        alert("!setPage: "+setPage);   
    };


    
    useEffect(()=>{
        
      const getUsers = async() => {     
              setIsLoading(1);
              
              let apiStock = {}; 
              if(typeof company === 'object' && company.id > 0) { 
                apiStock = await axiosClient.get(`/companylistusers/${company.id}?page=${currentPage}`);  
               // alert(JSON.stringify(apiStock.data.meta.total));
              // alert(JSON.stringify(apiStock.data.meta)); 
                setTotalPage(apiStock.data.meta.last_page);    
                setUsers(apiStock.data.data);
                let data = [];
                if(apiStock.data.meta.last_page > 0) {  
                    for(let i = 0; i < apiStock.data.meta.last_page; i++) {
                        data.push(i+1);  
                    }   
                     
                    setOutputp(data);
                    setIsmounted(1);
                    alert("----------||||||"+JSON.stringify(outputp))  
                    
                   

                }         
              }
              else { 
              
              if(userRole === "SuperAdmin") { 
  
                      apiStock = await axiosClient.get(`/listusers?page=${currentPage}`);  
                      //alert(JSON.stringify(apiStock.data.data)); 
                      setTotalPage(apiStock.data.meta.last_page);   
                    setUsers(apiStock.data.data);
                    let data = [];
                    if(apiStock.data.meta.last_page > 0) {  
                        for(let i = 0; i < apiStock.data.meta.last_page; i++) {
                            data.push(i+1);  
                        }   
                         
                        setOutputp(data); 
                        setIsmounted(1); 
                        
                    }    
              }
            }
              if(userRole === "Admin") { 
                  //alert(1);  
                  apiStock = await axiosClient.get(`/usersofbar/${userData.currentBar.id}`); 
                  //alert(JSON.stringify(apiStock.data.data));  
                  setUsers(apiStock.data.data);         
              } 
              setIsLoading(0);                      
      };  
      getUsers();   
  }, [currentPage]);   

    
    const [devices, setDevices] = useState([]); 

    useEffect(()=>{
        
        const getDevices = async() => {   
                setIsLoading(1); 
                
                let apiStock = {}; 

                        apiStock = await axiosClient.get(`/v1/devices`); 
                        setDevices(apiStock.data.data);  
                    //alert(JSON.stringify(apiStock.data))    

                                    
        }; 
        getDevices(); 
    }, []);  
    
    function handleChange(event) {
        console.log("handleChange "+event.target.value);
        let lengthI = event.target.value.length;
        if(lengthI >= 3) {
          const getUsers = async() => {    
            setIsLoading(1); 
            
            let apiStock = {}; 
                    apiStock = await axiosClient.get(`/listuserssearch/${event.target.value}`); 
                    setUsers(apiStock.data.data);       
                    setTotalPage(1)
                //alert(JSON.stringify(apiStock.data))                                       
          }; 
          getUsers();  
        }
      }

    return (
      <>
          {isLoading?     <div id="loading">   
          <div id="loading-center">
          </div>
        </div>: ''}   
                <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">

                    <Link to="/usercreate" className="btn btn-primary add-list">
                        <i class="las la-plus mr-3"></i>Add User
                    </Link>     
                </div> 

                <div class="table-responsive rounded mb-3">

                <input class="sc-bxDdli iObIWr" placeholder='Search...' type="text" onChange={handleChange}></input> 
                <Table id="userlistt" bordered responsive> 
            <thead>
                <tr>
                <th>№</th>
                <th>First Name</th>
                <th>Last Name</th> 
                <th>Username</th>
                <th>Roles</th>
                <th>Action Date</th> 
                <th>Account/Location</th> 
                <th>Status</th> 
                <th>Action</th>
              </tr> 
            </thead>
            <tbody>  
                    {users.map((user) => {
                    return (   
                        <tr>
                            <td>{user.id}</td>  
                            <td>{user.firstname}</td>   
                            <td>{user.lastname}</td>    
                            <td>{user.email}</td> 
                            <td>{user.role[0]}</td>  
                            <td>{user.created_at}</td>
                            <td>{user.company}</td>
                            <td><span class="badge badge-success ng-star-inserted">Active</span></td> 
                            <td>                              
                                <Link   to={'/user/' + user.id}>    
                                    <ButtonToolbar>  
                                     <ButtonGroup icons dir="ltr">
                                    <Button variant="outline-secondary"><span className="lnr lnr-pencil" /></Button>
                                     </ButtonGroup>
                                    </ButtonToolbar>
                                </Link> 
                            </td>                           
                        </tr>
                        );  
                    })}
                    </tbody>
            </Table>


<br /><br />
              <Paginator  totalPage={totalPage} currentPage={currentPage} outputPaginPages={outputp} ismounted={ismounted} handleClickedPage={handleClickedPage}  /> 


                </div>  
 
                

    </>
    );
};