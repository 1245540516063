/* eslint-disable */
import {React, useState, useEffect, useContext} from 'react'; 
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card'; 
import axiosClient from "../../axiosClient.jsx";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom"; 
import Collapse from '@/shared/components/Collapse';
import { AppContext } from '../../containers/App/App.jsx';
import Ulist from '../People/Ulist'; 
import Device from '../Device/Devices'; 


//import BoxedCollapse from "../UI/Collapse/components/BoxedCollapse";
//import BoxedCollapseFullWidth from "../UI/Collapse/components/BoxedCollapseFullWidth"; 

import './Style.css'; 

//import { Table } from '@/shared/components/TableElements';

import { Table } from "./Components/Table"; 

export default function Main (){

  const {company, setCompany} = useContext(AppContext);

  const location = useLocation();
  const { hash, pathname, search } = location; 

 
  if(pathname !== "/") return false;
    console.log(1);

    const companyUsers = (company_id) => {
      alert(`Hello, ${company_id}!`); 
      const elementClass = document.getElementsByClassName('company_'+company_id); 
      alert(elementClass[0].style);   
 
      elementClass[0].style = " ";     
    }; 

    //const userRole = "SuperAdmin"; 
    
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState([]);   

    
    function handleChange(event) {
      console.log("handleChange "+event.target.value);
      let lengthI = event.target.value.length;
      if(lengthI >= 3) {
        const getCompanies = async() => {   
          setIsLoading(1); 
          
          let apiStock = {}; 
                  apiStock = await axiosClient.get(`/v1/companieswithuserssearch/${event.target.value}`); 
                  setCompanies(apiStock.data);   

              //alert(JSON.stringify(apiStock.data))                                       
        }; 
        getCompanies(); 
      }
    }

    return (
        <>
        {company? <><Device /></>:    
  <Col md={12}> 
    <Card>
      <CardBody>
        <CardTitleWrap>
          <span class="headertablemain">
          {company? '':  
          <table >
            <tr>
              <td class="headerCTd1">
                <CardTitle>COMPANIES {company? company.id: ''} </CardTitle>  
                Companies & Location 
                </td>
              <td align='left' class="headerCTd2">
                <Link to="/addcompany" className="btn btn-primary add-list">
                  <i class="las la-plus mr-3"></i>Add new
                </Link>  
              </td>
              <td align='right' class="headerCTd3"> 
                <input class="sc-bxDdli iObIWr" placeholder='Search...' type="text" onChange={handleChange}></input>
              </td>
            </tr>
          </table>
          }
          </span>
          
        </CardTitleWrap>  

        <> 
 


        {/* {company? <Ulist />: <Table companiesSearch={companies} /> }  */} 

        <Table companiesSearch={companies} /> 
                  
                  {/* <div class="sc-jgaZKu jWDfwP"><ul class="pagination"><li class="pagination__item--arrow page-item disabled"><span class="page-link"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z"></path></svg></span><span class="visually-hidden">First</span></span></li><li class="pagination__item--arrow page-item disabled"><span class="page-link"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"></path></svg></span><span class="visually-hidden">Previous</span></span></li><li class="page-item active"><span class="page-link">1<span class="visually-hidden">(current)</span></span></li><li class="page-item"><a class="page-link" role="button" tabindex="0" href="#">2</a></li><li class="page-item"><a class="page-link" role="button" tabindex="0" href="#">3</a></li><li class="pagination__item--arrow page-item"><a class="page-link" role="button" tabindex="0" href="#"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path></svg></span><span class="visually-hidden">Next</span></a></li><li class="pagination__item--arrow page-item"><a class="page-link" role="button" tabindex="0" href="#"><span aria-hidden="true"><svg class="mdi-icon " width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z"></path></svg></span><span class="visually-hidden">Last</span></a></li></ul><div class="sc-chKlql hMGnbD">Showing 1 to 10 of 35</div><div class="sc-chKlql sc-jEItEs hMGnbD fBkzoO">
                  </div></div> */} 

                  

    </>

      </CardBody>
    </Card>
  </Col>
  }
  </>
    );
};