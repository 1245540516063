/* eslint-disable */
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import {
  Card, CardBody, CardTitleWrap, CardTitle, 
} from '@/shared/components/Card';
import axiosClient from "../../../../axiosClient.jsx";
import PricingCards from '../../../../containers/DefaultPage/PricingCards/index';
import WhiteBalanceSunnyIcon  from 'mdi-react/WhiteBalanceSunnyIcon';
import Icon from '@mdi/react';  
import RadiatorIcon  from 'mdi-react/RadiatorIcon';
import { mdiOfficeBuilding } from '@mdi/js';


const StackedAreaChart = ({ dir, title, type }) => {
  const [lastaccumulated, setAccumulated] = useState();
  const [lastcop, setLastcop] = useState(); 
  const [lastheatmeter, setLastheatmeter] = useState();
  const [lasttemperature, setLasttemperature] = useState();
  const [lasttemperaturekwh, setLasttemperaturekwh] = useState();
  const [lastheatpump, setlastheatpump] = useState();
  const [lastheatpumpout, setlastheatpumpout] = useState();
  const [addedminago, setAddedminago] = useState(0);
  
  
  
  

  const [data, setData] = useState( [
    {
      name: 'Page A', Temperature: 4000, Heat_Meter: 2400, COP: 2400,
    },
    {
      name: 'Page B', Temperature: 3000, Heat_Meter: 1398, COP: 2210,
    },
    {
      name: 'Page C', Temperature: 2000, Heat_Meter: 9800, COP: 2290,
    },
    {
      name: 'Page D', Temperature: 2780, Heat_Meter: 3908, COP: 2000,
    },
    {
      name: 'Page E', Temperature: 1890, Heat_Meter: 4800, COP: 2181,
    },
    {
      name: 'Page F', Temperature: 2390, Heat_Meter: 3800, COP: 2500,
    },
    {
      name: 'Page G', Temperature: 3490, Heat_Meter: 4300, COP: 2100,
    },
  ]);

  const { t } = useTranslation('common');

  useEffect(()=>{
    var url = '/enerpowercalclist1';
    if(type == 'enerpowercalclist2')
      url = '/enerpowercalclist2'; 
    axiosClient.get(url) 
    .then(({ data }) => {
      var curData = data.data;
      console.log('START......'+data);
      console.log("IIIIIIIIIIII----------+++++:"+JSON.stringify(data.data));
      //alert(id); 
      const array = [];
      console.log('11111111111!!!!!!!!!!');
      let i = 0;
      curData.map((d, index) => {
        console.log('map'); 
        i++;
        //alert(d.temperature);  
        let set = {name: d.created_at,  Temperature: d.data3, Heat_Meter: d.data4, COP: d.cop};     
        array.push(set);
        //console.log('curData.length: '+curData.length); 
        //console.log('index: '+index);  
        if(index + 1 === curData.length) { 
        //if(index === 0) { 
          setLastcop(d.cop)
          setLastheatmeter(d.data4);
          setLasttemperature(d.data3);
          setLasttemperaturekwh(d.temperaturekwh);
          if(url === '/enerpowercalclist1') {
            setlastheatpump(parseFloat(d.data1 / 1000).toFixed(3));
            setlastheatpumpout(d.humidity / 1000)  
            setAddedminago(d.added_min_ago);
            setAccumulated(d.accumulated);
            
          }
          if(url === '/enerpowercalclist2') {//alpha
            setlastheatpump(parseFloat(d.humidity).toFixed(3)); 
            setlastheatpumpout(parseFloat((d.data2 / 1000)).toFixed(3))          
            setAddedminago(d.added_min_ago);    
            setAccumulated(d.accumulated);
          } 
          console.log('last cop: '+d.cop); 
        }  
          
        //console.log('map end'); 
      })
      console.log("IIIIIIIIIIII:"+JSON.stringify(array));
      setData(array); 
      console.log('START.');
      console.log('END.'); 
    })
    .catch((error) => {

    });


  }, []);

  return (
    <>
    <Col xs={12} md={12} lg={6} xl={4}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            {/*<CardTitle>{title}</CardTitle>*/} 
<div class="list-pie-title">

<div class="relative"> 
<div class="absolute sunnyiconintitle">
 <Icon path={mdiOfficeBuilding} size={2} />  
</div>   
</div>

<span class="pie-block-title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{title}</span><br /> 
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-updated">Updated {addedminago} minutes ago</span>
</div> 
          </CardTitleWrap>   
          <div>
          <PricingCards type1={title} cop={lastcop} heatmeter={lastheatmeter} temperature={lasttemperature} temperaturekwh={lasttemperaturekwh} heatpump={lastheatpump} heatpumpout={lastheatpumpout} accumulated={lastaccumulated}  />
      </div> 
          <div class="relative"> 
          <div class="absolute chart-select-time"> 
            {/* 
        <select>
          <option value="">12 hours</option>
          <option>1 days</option>
          <option>1 week</option>
        </select> 
        */}
        </div>
        </div>
          <div dir="ltr">
            <ResponsiveContainer width={1115} height={300}> 
              <AreaChart 
                data={data} 
                margin={{
                  top: 0, right: 0, left: -15, bottom: 0,
                }}
              >
                <XAxis dataKey="name" reversed={dir === 'rtl'} />
                <YAxis orientation={dir === 'rtl' ? 'right' : 'left'} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="Temperature" stackId="1" stroke="#24d6a3" fill="#4ce1b6" />
                <Area type="monotone" dataKey="Heat_Meter" stackId="1" stroke="red" fill="#f6da6e" />
                <Area type="monotone" dataKey="COP" stackId="1" stroke="#3ea3fc" fill="#70bbfd" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>
    </Col>
    </>
  );
};

StackedAreaChart.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default StackedAreaChart;
                                       