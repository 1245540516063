/* eslint-disable */
import {React, useState, useEffect, useContext} from 'react'; 
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitle, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card'; 
import axiosClient from "../../axiosClient.jsx";
import { useParams, useNavigate, Link } from "react-router-dom";

import SimpleLineChart from '../../containers/Charts/Recharts/components/SimpleLineChart';
import StackedAreaChart from '../../containers/Charts/Recharts/components/StackedAreaChart';

import { AppContext } from '../../containers/App/App.jsx'; 

import { BorderedBottomTabs, JustifyTabs } from '@/shared/components/Tabs';

import WhiteBalanceSunnyIcon  from 'mdi-react/WhiteBalanceSunnyIcon';
import RadiatorIcon  from 'mdi-react/RadiatorIcon';

const bdlogo = `${process.env.PUBLIC_URL}/img/Becton_Dickinson_logo.png`; 
const live_data = `${process.env.PUBLIC_URL}/img/live_data.png`; 




import PricingCards from '../../containers/DefaultPage/PricingCards/index';
//import Tabs from '../UI/Tabs/components/Tabs'; 
//import JustifyTabsBorderedBottom  from '../UI/Tabs/components/JustifyTabsBorderedBottom'; 
//import DeviceJustifyTabsBorderedBottom  from './DeviceJustifyTabsBorderedBottom'; 

import PieChartWithNeedle2 from '../../containers/Charts/Recharts/components/PieChartWithNeedle2';

export default function Devices (){ 
    const {company, setCompany} = useContext(AppContext); 
    console.log(1); 

    //const userRole = "SuperAdmin"; 
    
    const [devices, setDevices] = useState([]);
    const [isLoading, setIsLoading] = useState([]); 

    useEffect(()=>{
         
        const getDevices = async() => {   
                setIsLoading(1); 
                
                let apiStock = {}; 
                
                /*
                if(typeof company === 'object' && company.id > 0) { 
                 // alert("1111111:::::::::: "+JSON.stringify(company));  
                  apiStock = await axiosClient.get(`/devicesbycompany/${company.id}`);
                  setDevices(apiStock.data.data);   
                }
                else {
                  apiStock = await axiosClient.get(`/v1/devices`); 
                  setDevices(apiStock.data.data); 
                }
                */

                apiStock = await axiosClient.get(`/devicesbycompany/2`); 
                setDevices(apiStock.data.data);  
                          

                                    
        }; 
        getDevices(); 
    }, [company]);   

    return (
        <>
        <div> 
        <img class="bdlogo" src={bdlogo}  /><br /> 
        <span class="kroshki">Dashboards &#8594; Becton Dickinson</span><br /> 
        <table class="tb1" width='100%'>
          <tr>
            <td class="bd-td-1"><h4 class="company-title">Becton Dickinson </h4></td>
            <td class="bd-td-2"><img class="live_data" src={live_data}  /></td>
          </tr>
          </table>    
  {/*  
  <Col class="dradius" md={12}>  
    <Card>         
      <CardBody>

        <div class="list-pie-title">

        <div class="relative">
        <div class="absolute sunnyiconintitle">
          <WhiteBalanceSunnyIcon />
        </div>
        </div>

        <span class="pie-block-title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOLAR PANEL ENERGY METERS</span><br /> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current Energy Output kWh 
        </div>
                  {devices? 
                    <> 
                    {devices.map((device) => {
                      let proc = 0;
                      let max = 100;
                      let outputvalue = 0;
                      if(device.id == 23) {  
                        //alert(device.data2); 
                        proc = (device.data2 / 100000) * 100;
                        max = '100,000';
                        outputvalue = device.data2;
                      } 
                      else {
                        proc = (device.data1 / 100000) * 100;
                        max = '100,000'; 
                        outputvalue = device.data1;
                      } 
                      let pieData = {titleD: device.output_name, max: max, value: proc, outputvalue: outputvalue, link : '/devicedata/' + device.id}; 
                      if(device.filter == 'kWh') {   
                    return ( 
                      <div class="pielist">
                        <PieChartWithNeedle2 data={pieData}/> 
                      </div> 
                        );
                      }
                      })}
                      </>
                   : '' }  


        <br /><br />                 

      </CardBody>
    </Card>
  </Col>
*/}


  <Col md={12}> 
    <Card>
      <CardBody>

{/*
<div class="list-pie-title"> 
<div class="relative">
        <div class="absolute sunnyiconintitle">
          <RadiatorIcon />
        </div>
        </div>
        <span class="pie-block-title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HEAT METERS</span><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Current output in celcius
        </div> 
        
                  {devices? 
                    <> 
                    {devices.map((device) => {
                      let proc = 0;
                      let max = 100;
                      let outputvalue = 0;
                      if(device.id == 23) { 
                        //alert(device.data2); 
                        proc = device.temperature;
                        max = '100';
                        outputvalue = device.temperature; 
                      }  
                      let pieData = {titleD: device.output_name, max: max, value: device.temperature, outputvalue: device.temperature, link : '/devicedata/' + device.id}; 
                      if(device.filter == 'Celcius') {
                    return (  
                      <div class="pielist">
                        <PieChartWithNeedle2 data={pieData}/>  
                      </div> 
                        );
                      }
                      })}
                      </>
                   : '' } 
                  */ }  
         
<StackedAreaChart title="BETA" type="enerpowercalclist1" /> 
<a href="https://protracs.io/csv/enerpowercalclist1.php">Export</a>
<br />
<div class="relative">
<div class="absolute ener-separator"></div>  
</div> 
<br />
<StackedAreaChart title="ALPHA" type="enerpowercalclist2" /> 
<a href="https://protracs.io/csv/enerpowercalclist2.php">Export</a> 
      </CardBody>
    </Card>
  </Col>
  </div>
  </> 



    );
};