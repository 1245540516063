/* eslint-disable */
import {createRef, useState, useEffect} from "react";
import axiosClient from "../../axiosClient.jsx";
import { useParams, Link, useHistory } from "react-router-dom";  

import { Field, Form, FormSpy } from 'react-final-form';

import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import LinkIcon from 'mdi-react/LinkIcon';//https://pictogrammers.com/library/mdi/
import DomainIcon from 'mdi-react/DomainIcon';

'mdi-react'
import WebIcon from 'mdi-react/WebIcon';  
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import PasswordField from '@/shared/components/form/Password';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar, 
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

import { Col } from 'react-bootstrap'; 

import renderDropZoneField from './DropZone';

import validate from './validate';   


//import FormValidation from '../Form/ReactFinalForm/FormValidation/index';
import FormField from '@/shared/components/form/FormField';

import Qs from "qs";  


export default function UserManage() {    
    const history = useHistory(); 
    const userRole = "SuperAdmin"; 

 
    const {id} = useParams(); 
    const [firstValues, setFirstValues] = useState(0)

 
    const [company, setCompany] = useState({
        id: null,
        name: '', 
        email: '', 
        whiteLabel: false,
    })

    const nameRef = createRef() 
    const emailRef = createRef() 
    const passwordRef = createRef()
    const passwordConfirmationRef = createRef() 
    const statusRef = createRef()  

    const [errors, setErrors] = useState(null)
  
    const onSubmit = async (values) => { 

      var sData =  values;
      sData.status = 1;
      if (typeof values.white_label !== "undefined")
        sData.white_label = values.white_label[0];
      if (typeof values.logo !== "undefined") 
        sData.logo = values.logo[0].filename; 
      if (typeof values.favicon !== "undefined")
        sData.favicon = values.favicon[0].filename;
      //window.alert(`You submitted:\n\n${JSON.stringify(sData, null, 2)}`);
      let url = '';  
      if(id) {
        url = '/companyupdate';  
        sData.id = id;   
      }
      else {
        url = '/store'; 
        //url = 'test.php';   
        //payload.id = id 
      } 
      axiosClient.post(url, Qs.stringify(sData))
      .then(({data}) => {
          return history.push('/'); 
          //alert("added!")     
      })
      .catch(err => { 
        const response = err.response;
        if (response && response.status === 422) {
          setErrors(response.data.errors) 
        }
      }) 

    }

    if(id) { 
        useEffect(()=>{
            const getStocks = async() => {

                const apiStock = await axiosClient.get(`/companybyid/${id}`);  
 
                setCompany(apiStock.data);  

            };
            getStocks();
        }, []);
    } 

    const fetchData = async () => {
      // Perform AJAX call here, for example using fetch or axios
      const Cuser = await axiosClient.get(`/companybyid/${id}`);
      //alert(JSON.stringify(Cuser.data));  
      return Cuser.data; 
    };

    return (
        <>


                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <div class="header-title"> 
                            <h4 class="card-title">{company.id ?'Edit': 'Add'} company</h4>
                        </div>
                    </div>

                </div>
                <br />
                <Col xs={12} md={12} lg={12} xl={6}>
                <Card>
                <CardBody>
                <Form onSubmit={onSubmit} validate={validate}> 
            {({ handleSubmit, form, values }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>

<FormSpy
            subscription={{ values: true }}
            onChange={async (state) => {
              
              if(firstValues === 0) {
                
              // Fetch data when form is initialized or whenever form values change
              const data = await fetchData();  
                 
                form.change('name', data.name); 
                form.change('url', data.url); 
                if(data.logo !== '') {
                  form.change('white_label', 1);
                  form.change('link_terms_of_service', data.link_terms_of_service); 
                  form.change('link_privacy_policy', data.link_privacy_policy);                     
                } 

              setFirstValues(1) 
              }
              // Set form values with the fetched data
              //form.mutators.setValues(data);
            }}
          /> 


                <FormGroup> 
                  <FormGroupLabel>{typeof values.individual !== "undefined" && values.individual[0] == 1 ?'Individual': 'Company'} Name</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <DomainIcon /> 
                    </FormGroupIcon>
                    <Field
                      name="name"
                      component={FormField}
                      type="text"
                      placeholder=""
                      isAboveError  
                    />
                  </FormGroupField>
                </FormGroup>

                <FormGroup>
                  <FormGroupLabel>Url</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <WebIcon />
                    </FormGroupIcon>
                    <Field
                      name="url"
                      component={FormField}
                      type="text"
                      placeholder=""
                    />
                  </FormGroupField>
                </FormGroup>

                <FormGroup>
                  <FormGroupLabel>Enable white label </FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="white_label"
                      component={renderCheckBoxField} 
                      label=""
                      value="1"
                      type="checkbox" 
                    />
                  </FormGroupField>
                </FormGroup>
                
                {values.white_label == 1 &&
                <> 
                <FormGroup>
                  <FormGroupLabel>White Label Url</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <WebIcon />
                    </FormGroupIcon>
                    <Field
                      name="white_label_url"
                      component={FormField}
                      type="text"
                      placeholder=""
                    />
                  </FormGroupField>
                </FormGroup>
                

                <FormGroup>
                  <FormGroupLabel>Logo</FormGroupLabel>
                  <FormGroupField> 
                  <Field
                    name='logo'
                    component={renderDropZoneField}
                    />
                    <br />
                  </FormGroupField>                  
                </FormGroup>

                <FormGroup>
                  <FormGroupLabel>Favicon</FormGroupLabel>
                  <FormGroupField> 
                  <Field
                    name='favicon'
                    component={renderDropZoneField}
                    />
                    <br />
                  </FormGroupField>                  
                </FormGroup>

                <FormGroup>
                  <FormGroupLabel>Link to Terms of Service</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <LinkIcon />
                    </FormGroupIcon>
                    <Field
                      name="link_terms_of_service"
                      component={FormField}
                      type="text"
                      placeholder=""
                    />
                  </FormGroupField>
                </FormGroup>

                <FormGroup>
                  <FormGroupLabel>Link to Privacy Policy</FormGroupLabel>
                  <FormGroupField>
                    <FormGroupIcon>
                      <LinkIcon />
                    </FormGroupIcon>
                    <Field
                      name="link_privacy_policy"
                      component={FormField}
                      type="text"
                      placeholder=""
                    />
                  </FormGroupField>
                </FormGroup> 
                </>
              }


                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Submit</Button>
                  <Button variant="secondary" type="button" onClick={form.reset}>
                    Cancel
                  </Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>

          {/*  <FormValidation/>  */}

          </CardBody>
          </Card>
          </Col>

      </>
    )
}