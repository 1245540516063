/* eslint-disable */
import {React, useState, useEffect, useContext} from 'react'; 
import PropTypes from 'prop-types';
import { colorBorder, colorBackground, colorHover } from '@/utils/palette';
import { left } from '@/utils/directions';
import styled from 'styled-components';
import SidebarLink, { SidebarLinkTitle, SidebarNavLink } from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { AppContext } from '../../../containers/App/App.jsx';  

const SidebarContent = ({
  onClick,
  collapse,
  changeToLight,
  changeToDark,
}) => {
  const {company, setCompany} = useContext(AppContext);
return (
  <SidebarContentWrap collapse={collapse}>
 
    <SidebarBlock collapse={collapse}>
      <SidebarLink title="Log In" icon="exit" route="/log_in" onClick={onClick} />
      <SidebarCategory title="Layout" icon="layers" collapse={collapse}>
        <SidebarNavLink as="button" type="button" onClick={changeToLight}>
          <SidebarLinkTitle>Light Theme</SidebarLinkTitle>
        </SidebarNavLink>
        <SidebarNavLink as="button" type="button" onClick={changeToDark}>
          <SidebarLinkTitle>Dark Theme</SidebarLinkTitle>
        </SidebarNavLink>
      </SidebarCategory>
    </SidebarBlock>  

    {company? '':
    <>  
    <SidebarCategory title="Device" icon="layers" collapse={collapse}>
        <SidebarLink title="Devices" route="/devices" onClick={onClick} />
        <SidebarLink title="Add Device" route="/adddevice" onClick={onClick} />
    </SidebarCategory>  

    <SidebarCategory title="Company" icon="layers" collapse={collapse}>
        <SidebarLink title="Companies" route="/companies" onClick={onClick} />
        <SidebarLink title="Add Company" route="/addcompany" onClick={onClick} />
        <SidebarLink title="Main" route="/main" onClick={onClick} /> 
    </SidebarCategory> 

    <SidebarCategory title="People" icon="layers" collapse={collapse}>
        <SidebarLink title="Users" route="/users" onClick={onClick} /> 
        <SidebarLink title="User Create" route="/usercreate" onClick={onClick} /> 
    </SidebarCategory>

    <SidebarCategory title="Profile" icon="layers" collapse={collapse}>
        <SidebarLink title="Personal Information" route="/personal-information" onClick={onClick} />
        <SidebarLink title="Change Password" route="/change-password" onClick={onClick} />
    </SidebarCategory>
    </> 
    } 

  </SidebarContentWrap>
);
}

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  collapse: PropTypes.bool,
  changeToLight: PropTypes.func.isRequired,
  changeToDark: PropTypes.func.isRequired,
};

SidebarContent.defaultProps = {
  collapse: false,
};

export default SidebarContent;

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;
  
  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
  
  @media screen and (min-width: 576px) {
    padding-top: 15px;
    
    ${props => props.collapse && `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;
  
  &:last-child {
    border: none;
  }
  
  @media screen and (min-width: 576px) {
    
    ${props => props.collapse && `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;

// endregion
