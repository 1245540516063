/* eslint-disable */
import { createGlobalStyle } from 'styled-components';
import {
  colorBlue,
  colorBlueHover,
  colorText,
  colorAdditional,
  colorAccent,
  colorWhite,
  colorRed,
  colorBackgroundBody,
  scrollbarStyles,
} from '@/utils/palette';
import {
  left,
  borderLeft,
  paddingLeft,
} from '@/utils/directions';

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  main {
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0 !important;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 1.6;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .abstotemp {
    left: 150px;
    top: 210px; 
  }

  .pietitle {
    left: 110px;  
    top: 230px;   
  }

  .mintotemp {
    left: 40px;
    top: 190px; 
  }

  .maxtotemp {
    left: 260px;
    top: 190px; 
  }

  .btn-add {
    color: black !important;
    text-decoration: underline;
  }

  .pie-block-title {
    font-weight: 600;
  }

  .main-companies {
    box-shadow: 2px 2px 2px #F7F7F7;
  }
  .row > * {
    padding: 0 15px;
  }

  * {
    box-sizing: inherit;
  }

  ul, ol {
    // doesn't do RTL, it break a sidebar
    padding-left: 15px;
    margin-bottom: 0;
    list-style-image: url(${process.env.PUBLIC_URL}/img/circle.svg);
  }

  a {
    color: ${colorBlue};
    transition: all 0.3s;
    text-decoration: none;

    &:hover {
      color: ${colorBlueHover};
    }
  }

  button:focus, button:active {
    outline: none;
  }

  img {
    width: 100%;
  }

  label {
    margin-bottom: 8px;
  }

  //TYPOGRAPHY
  p, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 0;
    color: ${colorText};
  }

  h1 {
    font-size: 36px;
    line-height: 48px;

    &.subhead {
      font-size: 30px;
      color: ${colorAdditional};
      line-height: 36px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 36px;

    &.subhead {
      font-size: 24px;
      color: ${colorAdditional};
      line-height: 32px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 32px;

    &.subhead {
      font-size: 18px;
      color: ${colorAdditional};
      line-height: 24px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 24px;

    &.subhead {
      font-size: 12px;
      color: ${colorAdditional};
      line-height: 16px;
    }
  }

  h5 {
    font-size: 14px;
    line-height: 18px;

    &.subhead {
      font-size: 10px;
      color: ${colorAdditional};
      line-height: 12px;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 16px;

    &.subhead {
      font-size: 8px;
      color: ${colorAdditional};
      line-height: 10px;
    }
  }
  
  hr {
    opacity: 0.1;
  }
  
  .table-responsive {
    ${scrollbarStyles};
  }

  blockquote {
    font-size: 13px;
    font-style: italic;
    ${borderLeft}: 3px solid ${colorAccent};
    margin-bottom: 0;
    text-align: ${left};
    ${paddingLeft}: 10px;
  }

  .highlight {
    background-color: ${colorAccent};
    color: ${colorWhite};
  }

  .typography-message{
    text-align: ${left};
  }

  .red-text {
    color: ${colorRed};
  }

  .page-title {
    text-align: ${left};
    font-weight: 500;
    text-transform: capitalize;
    font-size: 20px;

    &:last-child {
      margin-bottom: 30px;
    }

    &.page-title--not-last {
      margin-top: 20px;
    }
  }

  .page-subhead {
    text-align: ${left};
    margin-bottom: 20px;

    &.subhead {
      font-size: 14px;
      opacity: 0.7;
    }
  }

  ::selection {
    color: ${colorWhite};
    background: ${colorAccent};
  }

  .first-letter-uppercase:first-letter {
    text-transform: uppercase;
  }

  .container {

    @media screen and (min-width: 768px) {
      width: 100%;
      max-width: 1630px;
    }
  }


  .rc-slider-tooltip {
    padding-bottom: 2px;

    .rc-slider-tooltip-inner {
      margin-bottom: 5px;
      background-color: ${colorBackgroundBody};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
      color: ${colorText};
      font-size: 12px;
      line-height: 12px;
      padding: 4px 4px;
      height: 20px;
      min-width: 28px;
      border-radius: 0;
    }

    &.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
      border-top-color: ${colorBackgroundBody};
    }

    &.rc-slider-tooltip-hidden {
      display: block;
    }
  }

  .eAlkkX {
    padding-left: 20px;
  }

  .leftmenu {
    display:none;
  }

  .pielist {
    display: inline-block;
    width: 350px; 
  }

  .block-logo {
    padding-left:30px;
    padding-top:20px;   
  }

  .block-logo img {
    max-width: 150px;
  }

  .login-link {
    color: #fcba32 !important;
  }

  #login-button {
    border-color: #292929 !important; 
    color: #292929 !important;
  }

  #login-button:hover {
    background-color: white !important; 
  }

  .company-title {
    margin-bottom:20px; 
  }

  .card-body {
    border-radius: 5px;
  }

  .sunnyiconintitle {
    left: -28px;   
    top:-1px;   
  }

  .bdlogo {
    width: 50px;
    padding-bottom: 10px;
  }

  .bdheader1 {
    display: inline-block;
    width: 30%;
    border: 1px solid black;
  }
  .bdheader2 {
    display: inline-block;
    width: 70%;
  }

  .tb1 {
    width: 100%;
    background-color: #F2F4F7 !important; 
    margin-top: 4px; 
    border: 0 !important; 
    border-bottom: 0 !important;
  } 

  .bd-td-1 {
    padding: 0;
  }

  .bd-td-2 {
    float: right;
    padding: 0;
  }

  .live_data {
    width: 100px;
  }

  .kroshki {
    color: #B4B4B5;
  }

  .chart-select-time {
    right: -300px;
    bottom: 30px;
  }
  
  .rowwidth {
    width: 500px; 
  }

  .cardcurvalue {
    font-size: 22px; 
  }

  .eCOETM, .text-updated { 
  text-transform:none !important; 
  }

.ener-separator { 
  background: #f2f4f7;
  width: 2000px;
  left: -20px; 
  height: 20px;  
}

.recharts-sector {     
  position: relative; 
  background: conic-gradient(from 245deg, rgb(10, 132, 255) 0deg, rgb(126, 211, 33) 62.7273deg, rgb(255, 214, 10) 125.455deg, rgb(255, 69, 58) 230deg);
  z-index: 9999;
}

.pieh {
background-color: white;
margin-right: 20px; 
width: 27%;
min-width: 300px; 
}

.tempblocktitle {
  top:10px;
}

.iWaOHx {
/* padding: 45px 20px !important; */
/* background-color: grey; */
}

.hkwzsA {
margin-bottom: 0px; 
}

.clsubtext {
font-size: 12px;
}

`;

export default GlobalStyles;
