/* eslint-disable */
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types'; 
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import axios from 'axios';
import axiosClient from "../../../../axiosClient.jsx";



const SimpleLineChart = ({ dir }) => {

  const [data, setData] = useState( [
    {
      name: 'Page A', uv: 4000,
    },
    {
      name: 'Page B', uv: 3000,
    },
    {
      name: 'Page C', uv: 2000,
    },
    {
      name: 'Page D', uv: 2780,
    },
    {
      name: 'Page E', uv: 1890,
    },
    {
      name: 'Page F', uv: 2390,
    },
    {
      name: 'Page G', uv: 3490, 
    },
  ]);

  useEffect(()=>{
    console.log('START......');
    //axios.get('https://qq1h4pavsh.execute-api.eu-west-1.amazonaws.com/prod/sensortemp')
    axiosClient.get('/devicedatabydeviceid/13') 
    .then(({ data }) => {
      console.log('START......'+data);
      alert(JSON.stringify(data)); 
      const array = [];
      console.log('11111111111!!!!!!!!!!');
      let i = 0;
      data.map((d) => {
        console.log('map'); 
        i++;
        let set = {name: 'Sensor '+i, uv: d.temperature}; 
        array.push(set);
        //console.log('map end'); 
      })
      console.log("IIIIIIIIIIII:"+JSON.stringify(array));
      setData(array); 
      console.log('START.');
      console.log('END.'); 
    })
    .catch((error) => {

    });


  }, []);

  const { t } = useTranslation('common');

  return (
    <Col xs={12} md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{t('charts.recharts.simple_line_chart')}</CardTitle>
          </CardTitleWrap>
          <div dir="ltr">
            <ResponsiveContainer height={300}>
              <LineChart
                data={data}
                margin={{
                  top: 0, right: 0, left: -15, bottom: 0,
                }}
              >
                <XAxis dataKey="name" reversed={dir === 'rtl'} />
                <YAxis orientation={dir === 'rtl' ? 'right' : 'left'} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#4ce1b6" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="uv" stroke="#70bbfd" />
                <Line type="monotone" dataKey="amt" stroke="#f6da6e" activeDot={{ r: 6 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

SimpleLineChart.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default SimpleLineChart;
