/* eslint-disable */
import {createRef, useState, useEffect, useContext} from "react";
import Qs from "qs"; 

import axiosClient from "../../axiosClient.jsx"; 
import { useParams, Link, useHistory } from "react-router-dom";  

import { Field, Form, FormSpy } from 'react-final-form';
import validate from './validate';  
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
  } from '@/shared/components/Card'; 
  
import { Col } from 'react-bootstrap'; 

import {
    FormButtonToolbar, 
    FormContainer,
    FormGroup,
    FormGroupField,
    FormGroupIcon,
    FormGroupLabel,
  } from '@/shared/components/form/FormElements'; 

  import FormField from '@/shared/components/form/FormField';

  import renderCheckBoxField from '@/shared/components/form/CheckBox';

  import { Button } from '@/shared/components/Button';

  import renderSelectField from '@/shared/components/form/Select';
  import { AppContext } from '../../containers/App/App.jsx';    

  const generatePassword = () => {
    // Your password generation logic here
    // This is a simple example, you can use a library like `crypto` for more secure password generation
    const password = Math.random().toString(36).slice(-8);
    return password;
  };

  

export default function UserManage() {  

    const handleGeneratePassword = (form, field) => {
        const generatedPassword = generatePassword();
        setPasswordgenerated("   "+generatedPassword); 
        form.change(field, generatedPassword);
    }; 

    const {company, setCompany} = useContext(AppContext);
    
    const [firstValues, setFirstValues] = useState(0)
    const [passwordgenerated, setPasswordgenerated] = useState()

    const history = useHistory(); 
    const userRole = "SuperAdmin"; 

    const {id} = useParams();

 
    const [user, setUser] = useState({
        id: null,
        name: '', 
        email: ''
    })

    const nameRef = createRef() 
    const emailRef = createRef() 
    const passwordRef = createRef()
    const passwordConfirmationRef = createRef() 
    const roleRef = createRef() 

    const [errors, setErrors] = useState(null)
 

    const onSubmit = async (values) => { 


      let url = ''; 
      if(id) {
        url = '/updateuserfull';
        values.id = id;   
        //payload.id = id;  
      }
      else {
        url = '/signupfull';
        if(company.id > 0) {
          values.company_id = company.id;
        }
        //url = 'test.php';   
        //payload.id = id 
      } 

      var sData =  values; 
     // window.alert(`You submitted:\n\n${JSON.stringify(sData, null, 2)}`);

      axiosClient.post(url, Qs.stringify(sData))
      .then(({data}) => {
        //alert(`from server: \n\n${JSON.stringify(data, null, 2)}`);
          return history.push('/users'); 
          //alert("added!")     
      })
      .catch(err => { 
        const response = err.response;
        if (response && response.status === 422) {
          setErrors(response.data.errors) 
        }
      }) 

    }

    if(id) { 
        useEffect(()=>{
            const getStocks = async() => {

                const apiStock = await axiosClient.get(`/user/${id}`); 
 //alert(JSON.stringify(apiStock.data));  
                setUser(apiStock.data);  

            }; 
            getStocks();
        }, []);


    }
    
    const fetchData = async () => {
      // Perform AJAX call here, for example using fetch or axios
      const Cuser = await axiosClient.get(`/user/${id}`); 
      return Cuser.data; 
    };

 

    return (
        <> 
      
                      <Col xs={8} md={8} lg={12} xl={8}>
                      <Card>
                      <CardBody>
                      <Form onSubmit={onSubmit} validate={validate} generatePassword={generatePassword}> 
                                {/* Use FormSpy to listen to changes in the form state */}

                  {({ handleSubmit, form, values }) => (



                    <FormContainer horizontal onSubmit={handleSubmit}>

<FormSpy
            subscription={{ values: true }}
            onChange={async (state) => {
              if(firstValues === 0) {
              // Fetch data when form is initialized or whenever form values change
              const data = await fetchData(); 

                form.change('email', data.email); 
              form.change('firstname', data.firstname); 
              form.change('lastname', data.lastname); 
              form.change('role', data.role[0]);
              setFirstValues(1) 
              }
              // Set form values with the fetched data
              //form.mutators.setValues(data);
            }}
          /> 
      
      
                      <FormGroup> 
                        <FormGroupLabel>First Name {values.firstname}</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="firstname"
                            component={FormField}
                            type="text"
                            placeholder=""
                            isAboveError  
                          />
                        </FormGroupField>
                      </FormGroup>
      
                      <FormGroup>
                        <FormGroupLabel>Last Name</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="lastname"
                            component={FormField}
                            type="text"
                            placeholder=""
                            isAboveError
                          />
                        </FormGroupField>
                      </FormGroup>
  
      
                      <FormGroup>
                        <FormGroupLabel>Email</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="email"
                            component={FormField}
                            type="text"
                            placeholder=""
                            isAboveError
                          />
                        </FormGroupField>
                      </FormGroup>
      
                      <FormGroup>
                        <FormGroupLabel>Password</FormGroupLabel>
                        <FormGroupField>
                           <button onClick={() => handleGeneratePassword(form, 'password')} type="button" class="sc-dUbtfd cWBGco btn btn-primary">Generate password</button> 
                           <div class="generated password"> &nbsp;&nbsp;{passwordgenerated}</div>
                        </FormGroupField> 
                      </FormGroup> 

                      <FormGroup>
                        <FormGroupLabel></FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="password"
                            component={FormField}
                            type="password"
                            placeholder=""
                            isAboveError
                          />
                        </FormGroupField>
                      </FormGroup> 


                      <FormGroup>
                  <FormGroupLabel>Send User Notification </FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="send_user_notification"
                      component={renderCheckBoxField} 
                      label=""
                      value="1"
                      type="checkbox" 
                    /> 
                  </FormGroupField>
                </FormGroup>

                <FormGroup>
                  <FormGroupLabel>Role </FormGroupLabel>
                  <FormGroupField>
                  <Field
                      name="role"
                      component={renderSelectField}
                      type="text"
                      options={[
                        { value: 'Admin', label: 'Admin' },
                        { value: 'General', label: 'General' },
                      ]}
                      isAboveError
                    /> 
                  </FormGroupField>
                </FormGroup>
      
      
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Button variant="secondary" type="button" onClick={form.reset}>
                          Cancel
                        </Button>
                      </FormButtonToolbar>
                    </FormContainer>
                  )}
                </Form>
      
                {/*  <FormValidation/>  */}
      
                </CardBody>
                </Card>
                </Col>
      
            </>
    )
}