/* eslint-disable */
import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { useParams, useNavigate, Link } from "react-router-dom";
import Icon from '@mdi/react'; 
import { mdiThermometerLow } from '@mdi/js';

import { mdiWaterPercent } from '@mdi/js';

 
const RADIAN = Math.PI / 180;

const cx = 150;
const cy = 200;
const iR = 50;
const oR = 100;
      

 

export default class PieChartWithNeedle extends PureComponent {

  render() {

    var data = [
      { name: 'A', value: 18.75, color: '#0000FE' },    
      { name: 'B', value: 18.75, color: '#3065FF' },
      { name: 'C', value: 18.75, color: '#9CCEFF' }, 
      { name: 'D', value: 18.75, color: '#008100' }, 
      { name: 'E', value: 18.75, color: '#9BCF00' },
      { name: 'F', value: 18.75, color: '#FFCF00' },
      { name: 'G', value: 18.75, color: '#FF6501' },       
      { name: 'H', value: 18.75, color: '#FE0000' },  
      ];
      data.value = 100; 
    
    const needle = (value, data, cx, cy, iR, oR, color) => {
        let total = 0;
        data.forEach((v) => {
          total += v.value;
        });
        const ang = 180.0 * (1 - value / total); 
        const length = (iR + 2 * oR) / 3;
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const r = 5;
        const x0 = cx + 5;
        const y0 = cy + 5;
        const xba = x0 + r * sin;
        const yba = y0 - r * cos;
        const xbb = x0 - r * sin;
        const ybb = y0 + r * cos;
        const xp = x0 + length * cos; 
        const yp = y0 + length * sin;
      
        return [
          <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
          <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
        ]; 
      };  
    
    var value = this.props.data.value * 3.75;    
    //if(this.props.campany_name == 'Enerpower_Enniscorty') 
      //value = 21  + (21 * .99) + 0.3;    

    var max = this.props.data.max;

   
    //alert(JSON.stringify(this.props));  
    return (
        <>
         
        <div class="relative">
        <div class="absolute  tempblocktitle">
        {this.props.title == "Temperature" && <Icon path={mdiThermometerLow} size={1} />}
        {this.props.title == "Humidity" && <Icon path={mdiWaterPercent} size={1} />}
          {this.props.title}  
        </div>
            <div class="absolute mintotemp">
                0 
            </div>  
            <div class="absolute abstotemp">
                {this.props.data.outputvalue} 
            </div>
            <div class="absolute pietitle">
            <Link className="btn-add" to={this.props.data.link}>{this.props.data.titleD} {/*| {this.props.data.value}*/}  </Link>  
            </div>
            <div class="absolute maxtotemp">
                {max} 
            </div>   
        </div>
       
      <PieChart width={400} height={230}>
        <Pie
          dataKey="value"
          startAngle={180} 
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy} 
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(value, data, cx, cy, iR, oR, 'black')}
      </PieChart>  
      </>
    );
  }
}
