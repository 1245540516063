/* eslint-disable */
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card'; 
import axiosClient from "../../../../axiosClient.jsx";



const Data1Chart = ({devices, dir, id, field, title }) => {
  const [data, setData] = useState( [
    {
      name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
      name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
      name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
      name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
      name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
      name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
      name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
  ]);

  const { t } = useTranslation('common');

  useEffect(()=>{
    //alert("9999999: "+JSON.stringify(devices)); 
      let i = 0; 
      const array = [];
      devices.map((d) => { 
        console.log('map'); 
        i++;
        //alert(d.temperature);
        var gData = "";
        if(field == 'data1') 
          gData = d.data1;
        if(field == 'data2') 
          gData = d.data2;

        let set = {name: d.created_at, uv: gData};     
        array.push(set);
        //console.log('map end');  
      })
      console.log("IIIIIIIIIIII:"+JSON.stringify(array));
      setData(array); 
  }, [devices]);

  return (
    <Col xs={12} md={12} lg={6} xl={4}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>{title}</CardTitle>
          </CardTitleWrap>
          <div dir="ltr">
            <ResponsiveContainer width={500} height={300}>
              <AreaChart
                data={data}
                margin={{
                  top: 0, right: 0, left: -15, bottom: 0,
                }}
              >
                <XAxis dataKey="name" reversed={dir === 'rtl'} />
                <YAxis orientation={dir === 'rtl' ? 'right' : 'left'} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#24d6a3" fill="#4ce1b6" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

Data1Chart.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default Data1Chart;
