/* eslint-disable */
import {createRef, useState, useEffect} from "react";
import axiosClient from "../../axiosClient.jsx";
import { useParams, Link, useHistory } from "react-router-dom";   

 

export default function ChangePassword() {   
    const history = useHistory(); 
    const userRole = "SuperAdmin";
    
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [verifyPassword, setVerifyPassword] = useState();   

    const {id} = useParams();

 
    const [user, setUser] = useState({
        id: null,
        name: '', 
        email: ''
    })

    const nameRef = createRef() 
    const emailRef = createRef() 
    const passwordRef = createRef()
    const passwordConfirmationRef = createRef() 
    const roleRef = createRef() 

    const [errors, setErrors] = useState(null)
  
    const onSubmit = ev => { 
      ev.preventDefault()
  
      const payload = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
        password_confirmation: passwordConfirmationRef.current.value,
        role: roleRef.current.value, 
      } 

      console.log("payload!!!: "+JSON.stringify(payload)); 

      let url = ''; 
      if(id) {
        url = '/updateuser';
        payload.id = id;
      }
      else {
        url = '/signup';
        payload.id = id
      } 
      axiosClient.post(url, payload) 
        .then(({data}) => {
            return history.push('/users');   
        })
        .catch(err => { 
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors)
          }
        }) 
    }

    if(id) { 
        useEffect(()=>{
            const getStocks = async() => {

                const apiStock = await axiosClient.get(`/user/${id}`); 
 
                setUser(apiStock.data);  

            };
            getStocks();
        }, []);
    }

    return (
        <>

 

                <div class="card">

                    <div class="card-body">
                    {errors &&
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm text-red-900">
                        {Object.keys(errors).map(key => (
                            <p key={key}>{errors[key][0]}</p>
                        ))}
                        </div>
                    } 

<div class="tab-pane fade active show" id="personal-information" role="tabpanel">
                        <div class="card"> 
                           <div class="card-header d-flex justify-content-between">
                              <div class="iq-header-title">
                                 <h4 class="card-title">Personal Information</h4>
                              </div>
                           </div>
                           <div class="card-body">
                              <form onSubmit={onSubmit}>  
                                 <div class="form-group">
                                    <label for="cpass">Current Password:</label> 
                                    
                                    <input type="Password" class="form-control" id="cpass" onChange={(ev) => setOldPassword(ev.target.value)}  value={oldPassword}/>
                                 </div>
                                 <div class="form-group"> 
                                    <label for="npass">New Password:</label>
                                    <input type="Password" class="form-control" id="npass" onChange={(ev) => setNewPassword(ev.target.value)} value={newPassword}/>
                                 </div>
                                 <div class="form-group">
                                    <label for="vpass">Verify Password:</label>
                                    <input type="Password" class="form-control" id="vpass" onChange={(ev) => setVerifyPassword(ev.target.value)} value={verifyPassword}/>
                                 </div>
                                 <button type="submit" class="btn sidebar-bottom-btn">Submit</button>
                              </form>
                              </div>
                              </div>
                     </div>  
                    </div>
                </div>
      </>
    )
}