/* eslint-disable */
import {createRef, useState, useEffect, useContext} from "react"; 
import axiosClient from "../../axiosClient.jsx";
import { useParams, Link, useHistory } from "react-router-dom";   
import { AppContext } from '../../containers/App/App.jsx'; 
 

export default function PersonalInformation() { 
    const {userData, setUserData} = useContext(AppContext); 
    console.log("userData!!!: "+JSON.stringify(userData)); 
    
    const history = useHistory(); 
    const userRole = "SuperAdmin";
    
    const [userEmail, setUserEmail] = useState(null);
    const [userName, setUserName] = useState(null);
    const [message, setMessage] = useState(null)  

    const {id} = useParams();

 
    const [user, setUser] = useState({
        id: null,
        name: '', 
        email: ''
    })

    const nameRef = createRef() 
    const emailRef = createRef() 
    const passwordRef = createRef()
    const passwordConfirmationRef = createRef() 
    const roleRef = createRef() 

    const [errors, setErrors] = useState(null)
  
    const onSubmit = ev => { 
      ev.preventDefault()
  
      const payload = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
        password_confirmation: passwordConfirmationRef.current.value,
        role: roleRef.current.value, 
      } 

      console.log("payload!!!: "+JSON.stringify(payload)); 

      let url = ''; 
      if(id) {
        url = '/updateuser';
        payload.id = id;
      }
      else {
        url = '/signup';
        payload.id = id
      } 
      axiosClient.post(url, payload) 
        .then(({data}) => {
            return history.push('/users');   
        })
        .catch(err => { 
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors)
          }
        }) 
    }

    if(userData.user.id > 0) { 
        useEffect(()=>{
            const getStocks = async() => {

                const apiStock = await axiosClient.get(`/user/${userData.user.id}`); 
 
                setUser(apiStock.data);   

            };
            getStocks();
        }, []);
    }

    return (
        <>

 

                <div class="card">

                    <div class="card-body">
                    {errors &&
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm text-red-900">
                        {Object.keys(errors).map(key => (
                            <p key={key}>{errors[key][0]}</p>
                        ))}
                        </div>
                    } 
                        <form onSubmit={onSubmit} data-toggle="validator">

                        <div class="tab-pane fade active show" id="personal-information" role="tabpanel">
                        <div class="card"> 
                           <div class="card-header d-flex justify-content-between">
                              <div class="iq-header-title">
                                 <h4 class="card-title">Personal Information</h4>
                              </div>
                           </div>
                           <div class="card-body">
                              <form onSubmit={onSubmit}> 
                                 <div class="form-group">
                                    <label for="cno">Name:</label>
                                    <input type="text" class="form-control" id="cno"
                                    onChange={(ev) => setUserName(ev.target.value)} 
                                    value={userName}/>  
                                 </div>
                                 <div class="form-group">
                                    <label for="email">Email:</label>
                                    <input type="text" class="form-control" id="email" onChange={(ev) => setUserEmail(ev.target.value)}  value={userEmail}/>
                                 </div>
                                 <button type="submit" class="btn sidebar-bottom-btn">Submit</button>
                              </form>
                           </div>
                        </div>
                     </div>   
                            


                        </form> 
                    </div>
                </div>
      </>
    )
}